/* src/assets/fonts/vietnam-pro.css */
@font-face {
    font-family: 'VietnamPro';
    src: url('./BeVietnamPro-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'VietnamPro';
    src: url('./BeVietnamPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}